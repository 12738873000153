import React, { useState, useEffect }  from 'react';
import { Row, Col, Button, Select, Descriptions, Form } from 'antd';
import  {FormOutlined, SaveOutlined, CloseSquareOutlined}  from "@ant-design/icons";
import { useLazyQuery, useSubscription, useMutation } from '@apollo/client';
import Swal from "sweetalert2";

import { MapScreen } from './MapScreen';
import { DetailsList } from './DetailsList';
import { UsersVehiclesList } from './UsersVehiclesList';
import { VehicleDetails } from './VehicleDetails';
import { DriverDetails } from './DriverDetails';
import loggerCooltrack from '../../../../services/logger-cooltrack';
import { subscriptionFetchUsersVehicles } from '../../queries/homeQueries';
import { hasAssignedRouteFalse, updateMoveRouteComplet, updateRoutes, updateUserVehicle, updateRoutesBatch, update_UserVehicle, updateHasAssignedRouteCompleted,updateHasAssignedRouteCompletedTrue } from "../../queries/homeQueries";


import '../styles/HomeScreen.css';
import { OrdersDetail } from './OrdersDetail';
import localStorageService from '../../../../services/localStorageService';
export const HomeScreen = ({flag}) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState(JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses);
    const [usersVehicles, setUsersVehicles] = useState([]);
    const [selectedUserVehicle, setSelectedUserVehicle] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState({});
    const [collapseText, setCollapseText] = useState('Ocultar panel');
    const [viewMode, setViewMode] = useState(1);
    const [isUpdate, setIsUpdate] = useState(false);
    const [form] = Form.useForm();

    const { data } = useSubscription(subscriptionFetchUsersVehicles, {variables: {distributionCenterId: selectedWarehouse?.length > 0 ? selectedWarehouse : ["00000000-0000-0000-0000-000000000000"]}, fetchPolicy: 'no-cache'});
    const [getRouteRunning, dataRouteRunning] = useLazyQuery(hasAssignedRouteFalse,{variables: {distributionCenterId: selectedWarehouse?.length > 0 ? selectedWarehouse : ["00000000-0000-0000-0000-000000000000"]}, fetchPolicy: 'no-cache'}); 
    const [uptMoveRouteComplet] = useMutation(updateMoveRouteComplet, {
        fetchPolicy: "no-cache",
    });
    const [uptHasAssignedRouteCompleted] = useMutation(updateHasAssignedRouteCompleted, {
        fetchPolicy: "no-cache",
      });
    const [uptHasAssignedRouteCompletedTrue] = useMutation(updateHasAssignedRouteCompletedTrue, {
        fetchPolicy: "no-cache",
      });
    const [updateRoutesDriver] = useMutation(updateRoutes, {
        fetchPolicy: "no-cache",
    });
    const [uptUserVehicle] = useMutation(updateUserVehicle, {
        fetchPolicy: "no-cache",
    });
    const [uptRoutesBatch] = useMutation(updateRoutesBatch, {
        fetchPolicy: "no-cache",
    });
    const [upt_UserVehicle] = useMutation(update_UserVehicle, {
        fetchPolicy: "no-cache",
    });
  
    const toogleView = () => {
        setViewMode(current => current > 3 ? 1 : current+=1);
    };
    useEffect(() => {
        const warehouses = JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses;
        setSelectedWarehouse(warehouses);
    }, [flag]);
    useEffect(() => {
        getRouteRunning();
        
        if(data && data.userVehicle){
            if(selectedUserVehicle.length === 1) {
                
                const userVehicle = data.userVehicle.filter((item) => item.id === selectedUserVehicle[0].key);
                
                if(userVehicle.length > 0) {
                    
                    const record = {
                        key: userVehicle[0].id,
                        licencePlate: userVehicle[0].vehicle && userVehicle[0].vehicle.licencePlate ? userVehicle[0].vehicle.licencePlate : 'N/A',
                        displayName: userVehicle[0].user.displayName,
                        phoneNumber: userVehicle[0].user.phoneNumber,
                        headquarters: userVehicle[0].user.headquarters,
                        identificationNumber: userVehicle[0].user.identificationNumber,
                        capacity: userVehicle[0].vehicle && userVehicle[0].vehicle.weight ? userVehicle[0].vehicle.weight : 0,
                        userId: userVehicle[0].userId,
                        vehicleId: userVehicle[0].vehicleId,
                        orderQty: userVehicle[0].assignedRoutes_aggregate.aggregate.count,
                        lastLocation: userVehicle[0].lastLocation,
                        lastLocationUpdatedAt: userVehicle[0].updatedAt,
                        assignedRoutes: userVehicle[0].assignedRoutes,
                        vehicle: userVehicle[0].vehicle,
                        createdAt: userVehicle[0].createdAt
                    };
                    setSubscriptionData([record]);
                    
                }

            } else if(selectedUserVehicle.length === 0) {
                setSubscriptionData([])
                
            } else {

                setSubscriptionData([]);
                
                selectedUserVehicle.map((item) => {
                    const userVehicle = data.userVehicle.filter((userVehicle) => userVehicle.id === item.key);

                    return setSubscriptionData((current) => [...current, {
                        key: userVehicle[0].id,
                        licencePlate: userVehicle[0].vehicle && userVehicle[0].vehicle.licencePlate ? userVehicle[0].vehicle.licencePlate : 0,
                        displayName: userVehicle[0].user.displayName,
                        identificationNumber: userVehicle[0].user.identificationNumber,
                        headquarters: userVehicle[0].user.headquarters,
                        capacity: userVehicle[0].vehicle && userVehicle[0].vehicle.weight ? userVehicle[0].vehicle.weight : 0,
                        userId: userVehicle[0].userId,
                        vehicleId: userVehicle[0].vehicleId,
                        orderQty: userVehicle[0].assignedRoutes_aggregate.aggregate.count,
                        lastLocation: userVehicle[0].lastLocation,
                        lastLocationUpdatedAt: userVehicle[0].updatedAt,
                        assignedRoutes: userVehicle[0].assignedRoutes,
                        vehicle: userVehicle[0].vehicle,
                        createdAt: userVehicle[0].createdAt,
                    }]);
                });
                
            }
            
        }
    }, [data, selectedUserVehicle]);

    form.setFieldsValue({
        key: '',
        licencePlate: '',
        displayName: '',
        identificationNumber: '',
        capacity: '',
        userId: '',
        vehicleId: '',
        orderQty: '',
        lastLocation: '',
        lastLocationUpdatedAt: '',
        assignedRoutes: '',
        vehicle: '',
        createdAt: '',
        routeRoutingId: '',
        routeRoutingName: ''
      });

    const cargarDataOrder = (dataRouteRunningCurrent) => {
        setSelectedRoute({userNew: dataRouteRunningCurrent, userIdCurrent:selectedUserVehicle[0].userId, Route: subscriptionData[0]});
    }
    
    const handleSave = async () => {
      const {
        userIdCurrent,
        userNew,
        Route
      } = selectedRoute;

      const newUser = {
        displayName: userNew.user?.displayName,
        key:  userNew.id,
        licencePlate: userNew.vehicle?.licencePlate,
        orderQty: userNew.assignedRoutes_aggregate?.aggregate?.count,
        schedulingStartLocation: undefined,
        userId: userNew.userId,
        vehicleId: userNew.vehicleId,
      };
      for (let i = 0; i < Route?.assignedRoutes?.length; i++) {
        const dataAssignedRoutes = Route?.assignedRoutes[i];
        await uptMoveRouteComplet({
            variables: {
              id: dataAssignedRoutes.id,
              userId: newUser.userId,
              batch: dataAssignedRoutes.routeBatch?.batch,
              routeOrder: newUser.orderQty+1,
            },
          });
      }
      await uptHasAssignedRouteCompletedTrue({
        variables: {
            userId: newUser.userId,
            hasAssignedRoute: true
          },
      }).then(()=>{
      });
      await uptHasAssignedRouteCompleted({
        variables: {
            userId: userIdCurrent,
            hasAssignedRoute: false
          },
      }).then(()=>{
      })
      setSelectedUserVehicle([newUser]);

     Swal.fire({
        title: "Conductor Editado",
        text: "Conductor Editado correctamente!",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
     setIsUpdate(!isUpdate)
     setSelectedUserVehicle([]);
     loggerCooltrack({
        module: "Tracking",
        operation: "ChangeDriver",
        target: userNew.id,
    });
    
    }
//LoggerTrack
    return (
        <div>
            <Row>
                <Col span={24}>
                    <MapScreen
                        selectedUserVehicle={subscriptionData}
                        toogleView={() => toogleView()}
                        viewMode={viewMode}
                        children={
                            <section className="home-screen-container">
                                <Row gutter={[8, 8]}>
                                    
                                    <Col span={6} className={`home-screen-inner-col-container1 ${viewMode === 3 || viewMode === 4 ? "home-screen-inner-col-container-hidden" : ""}`}>
                                        <Row gutter={[0, 0]} className={`home-screen-inner-container11 ${subscriptionData.length === 0 || subscriptionData.length > 1 ? "home-screen-inner-col-container-hidden" : ""}`}>
                                            <VehicleDetails selectedUserVehicle={subscriptionData} />                                            
                                        </Row>
                                        <Row gutter={[0, 0]} className={`home-screen-inner-container12 ${subscriptionData.length === 0 || subscriptionData.length > 1 ? "home-screen-inner-container12-select" : ""}`}>
                                                <Col span={24}>
                                                    <Row> 
                                                        <section className="vehicle-details-header">                                                   
                                                            <p id="vehicle-details-label">Listado de Rutas</p>
                                                        </section>
                                                    </Row>
                                                    <Row>
                                                        <UsersVehiclesList
                                                            usersVehicles={usersVehicles}
                                                            setUsersVehicles={setUsersVehicles}
                                                            setSelectedUserVehicle={setSelectedUserVehicle}
                                                            data={data}
                                                            selectedUserVehicle={selectedUserVehicle}
                                                            flag={flag}
                                                        />
                                                    </Row>
                                                </Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col span={6} offset={viewMode === 3 ? 18 : 12} className={`home-screen-inner-col-container2 ${viewMode === 2 || viewMode === 4 || subscriptionData.length === 0 || subscriptionData.length > 1 ? "home-screen-inner-col-container-hidden" : ""}`}>
                                        <Row gutter={[0, 0]} className="home-screen-inner-container2">
                                            <Col span={24}>
                                                <Row> 
                                                    <section className="vehicle-details-header">                                                   
                                                        <p id="vehicle-details-label">Información de Ruta</p>
                                                    </section>
                                                    {!isUpdate ? (
                                                    <Button
                                                        className={`Editar_Orden_HeaderUploadScreen `}
                                                        style={{ marginTop: "10px", marginRight: "-30px" }}
                                                        type = "primary"
                                                        ghost = {!isUpdate}
                                                        shape="round"
                                                        icon={<FormOutlined />}
                                                        onClick={() => setIsUpdate(!isUpdate)}
                                                    >
                                                        
                                                    </Button>
                                                    ) : (
                                                    <Button
                                                        className={`Editar_Orden_HeaderUploadScreen `}
                                                        
                                                        style={{ marginTop: "10px", marginRight: "-30px" }}
                                                        type = "primary"
                                                        ghost = {!isUpdate}
                                                        shape="round"
                                                        icon={<SaveOutlined />}
                                                        onClick={handleSave}
                                                    >
                                                    </Button>
                                                    )}
                                                    
                                                </Row>
                                                <Row>
                                                <Form
                                                    form={form}
                                                >
                                                    <Descriptions 
                                                    style={{ marginBottom: "10px", fontSize:"5px" }}
                                                    bordered
                                                    size="small"
                                                    >
                                                    
                                                    {isUpdate ? (
                                                        <Descriptions.Item label="Editar Conductor" style={{fontSize:"11px" }}>
                                                        <Select
                                                            style={{width:"120px" }}
                                                            label= "Conductor"
                                                            placeholder="Seleccione..."
                                                            allowClear
                                                            onChange={(value, object) => {
                                                                form.setFieldsValue({
                                                                  routeRoutingId: value,
                                                                  routeRoutingName: object?.children,
                                                                });

                                                                cargarDataOrder(
                                                                    dataRouteRunning.data?.userVehicle?.filter(
                                                                    (item) => item.id === value
                                                                )[0]);
                                                              }}
                                                            
                                                        >
                                                            {
                                                               dataRouteRunning.called &&
                                                               dataRouteRunning.data !== undefined &&
                                                               dataRouteRunning.data?.userVehicle?.map((item) => {
                                                                   return (
                                                                   <Select.Option value={item.id}>
                                                                       {item.vehicle?.licencePlate} {item.user?.displayName}
                                                                   </Select.Option>
                                                                   
                                                                   );
                                                               })}
                                                        
                                                        </Select>
                                                        <Button
                                                        type = "primary"
                                                        style={{position:"absolute"}} 
                                                        icon= {<CloseSquareOutlined 
                                                        onClick={() => {setIsUpdate(!isUpdate); }}/>}
                                                        
                                                        >   
                                                        </Button>
                                                        
                                                        </Descriptions.Item>
                                                        
                                                    ) : null}
                                                    
                                                    </Descriptions>
                                                    
                                                    </Form>
                                                    
                                                </Row>
                                                <Row> <DriverDetails selectedUserVehicle={subscriptionData} /> </Row>
                                                <Row> <OrdersDetail selectedUserVehicle={subscriptionData} CenterSelected={selectedWarehouse} /> </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                </Row>
                            </section>
                        }
                    />
                </Col>
            </Row>
        </div>
    )
}
